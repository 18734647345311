.option-ocr {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 15px !important;
  width: 195px !important;

  label {
    margin: 0 !important;
    display: block !important;
    font-weight: bold !important;
    cursor: pointer !important;
  }

  input {
    margin: 0 10px !important;
  }
}
