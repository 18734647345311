@import "../../assets/scss/theme.scss";

.attention-box {
  background-color: rgb(248, 249, 250);
  border-radius: 10px;
  padding: 10px;
}

.guardian-title {
  color: #8392ab;
}

.choose-image {
  position: relative;
}

.button-close {
  position: absolute;
  top: 95.5px;
  right: 22px;
  width: 37px;
  height: 33px;
}

.tui-image-editor-header {
  display: none;
}

.tui-image-editor-menu {
  border: 1px solid #c1c1c1;
  border-right: none;
}

.tui-image-editor-container .tui-image-editor-main {
  top: 0;
}

.tui-image-editor-container .tui-image-editor-controls {
  background-color: #fff;
}

.tui-image-editor-container.left .tui-image-editor-controls li,
.tui-image-editor-container.right .tui-image-editor-controls li {
  border: 1px solid transparent;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item.active,
.tui-image-editor-container .tui-image-editor-help-menu > .tui-image-editor-item.active {
  border: 1px solid #555;
  border-radius: 3px;
}

.tui-image-editor-container.left .tui-image-editor-main-container {
  border: 1px solid #c1c1c1;
}
