.modal-tooltip-button {
  display: inline-block;
  margin: 0 8px;
  vertical-align: middle;

  span {
    opacity: 0.7;
    color: #000;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
